import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Col, Table, Row } from 'react-bootstrap';
import {
  DatatableWrapper,
  Filter,
  Pagination,
  TableBody,
  TableHeader,
  PaginationOptions,
} from 'react-bs-datatable';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import moment from 'moment';

import { allPurchases } from '../../redux/actions';
import Page from '../../layout/page';
import Spin from '../../components/Spin';

const header = [
  {
    title: 'Id',
    prop: 'id',
    cell: (row) => <Link to={{ pathname: `/purchases/purchase/${row.id}` }}>{row.id}</Link>,
    sortable: true,
    filterable: true,
  },
  { title: 'App Id', prop: 'app_id', sortable: true, filterable: true },
  {
    title: 'User Id',
    prop: 'custody_user_id',
    sortable: true,
    filterable: true,
  },
  { title: 'Edition Id', prop: 'edition_id', sortable: true, filterable: true },
  { title: 'Item Id', prop: 'item_id', sortable: true, filterable: true },
  { title: 'Platform', prop: 'platform', sortable: true, filterable: true },
  { title: 'Status', prop: 'status', sortable: true, filterable: true },
  {
    title: 'Created At',
    prop: 'createdAt',
    cell: (row) => moment(row.createdAt).format('dddd, MMMM Do YYYY, h:mm:ss a'),
    sortable: true,
    filterable: true,
  },
];

class Purchases extends Component {
  componentDidMount() {
    this.props.allPurchases();
  }

  render() {
    if (this.props.loading) {
      return <Spin />;
    }
    return (
      <Page title={'Purchases'}>
        <Col md={12} lg={12} xs={12}>
          <div className="card">
            <div className="card-header">All Purchases</div>
            <div className="card-body">
              <Row>
                <Col md={12} lg={12} xs={12}>
                  <DatatableWrapper
                    headers={header}
                    body={this.props.purchases}
                    paginationOptionsProps={{
                      initialState: {
                        rowsPerPage: 15,
                        options: [15, 20, 25, 30],
                      },
                    }}
                  >
                    <Row className="mb-4 p-2">
                      <Col
                        xs={12}
                        lg={4}
                        className="d-flex flex-col justify-content-end align-items-end"
                      >
                        <Filter />
                      </Col>
                      <Col
                        xs={12}
                        sm={6}
                        lg={4}
                        className="d-flex flex-col justify-content-lg-center align-items-center justify-content-sm-start mb-2 mb-sm-0"
                      >
                        <PaginationOptions />
                      </Col>
                      <Col
                        xs={12}
                        sm={6}
                        lg={4}
                        className="d-flex flex-col justify-content-end align-items-end"
                      >
                        <Pagination />
                      </Col>
                    </Row>
                    <Table>
                      <TableHeader />
                      <TableBody />
                    </Table>
                  </DatatableWrapper>
                </Col>
              </Row>
            </div>
          </div>
        </Col>
      </Page>
    );
  }
}

const mapStateToProps = (state) => ({
  loading: state.purchases.loading,
  purchases: state.purchases.purchases,
});
const mapDispatchToProps = (dispatch) => bindActionCreators({ allPurchases }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(Purchases);
