import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Col, Table, Row } from 'react-bootstrap';
import {
  DatatableWrapper,
  Filter,
  Pagination,
  TableBody,
  TableHeader,
  PaginationOptions,
} from 'react-bs-datatable';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import moment from 'moment';

import { allUsers } from '../../redux/actions';
import Page from '../../layout/page';
import Spin from '../../components/Spin';

const header = [
  {
    title: 'Id',
    prop: 'user_id',
    cell: (row) => <Link to={{ pathname: `/users/user/${row.user_id}` }}>{row.user_id}</Link>,
    sortable: true,
    filterable: true,
  },
  { title: 'App Id', prop: 'app_id', sortable: true, filterable: true },
  { title: 'Address', prop: 'pub_key', sortable: true, filterable: true },
  { title: 'Country', prop: 'country', sortable: true, filterable: true },
  {
    title: 'Created at',
    prop: 'createdAt',
    cell: (row) => moment(row.createdAt).format('dddd, MMMM Do YYYY, h:mm:ss a'),
    sortable: true,
    filterable: true,
  },
];

class Users extends Component {
  componentDidMount() {
    this.props.allUsers();
  }

  render() {
    if (this.props.loading) {
      return <Spin />;
    }
    return (
      <Page title={'Users'}>
        <Col md={12} lg={12} xs={12}>
          <div className="card">
            <div className="card-header">All Users</div>
            <div className="card-body">
              <Row>
                <Col md={12} lg={12} xs={12}>
                  <DatatableWrapper
                    headers={header}
                    body={this.props.usr}
                    paginationOptionsProps={{
                      initialState: {
                        rowsPerPage: 15,
                        options: [15, 20, 25, 30],
                      },
                    }}
                  >
                    <Row className="mb-4 p-2">
                      <Col
                        xs={12}
                        lg={4}
                        className="d-flex flex-col justify-content-end align-items-end"
                      >
                        <Filter />
                      </Col>
                      <Col
                        xs={12}
                        sm={6}
                        lg={4}
                        className="d-flex flex-col justify-content-lg-center align-items-center justify-content-sm-start mb-2 mb-sm-0"
                      >
                        <PaginationOptions />
                      </Col>
                      <Col
                        xs={12}
                        sm={6}
                        lg={4}
                        className="d-flex flex-col justify-content-end align-items-end"
                      >
                        <Pagination />
                      </Col>
                    </Row>
                    <Table>
                      <TableHeader />
                      <TableBody />
                    </Table>
                  </DatatableWrapper>
                </Col>
              </Row>
            </div>
          </div>
        </Col>
      </Page>
    );
  }
}

const mapStateToProps = (state) => ({
  loading: state.users.loading,
  usr: state.users.usr,
});
const mapDispatchToProps = (dispatch) => bindActionCreators({ allUsers }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(Users);
