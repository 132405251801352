import React from 'react';
import { Row, Col } from 'reactstrap';
import { reduxForm } from 'redux-form';

import { FormField } from './FormField';

const ArtistForm = (props) => {
  const { handleSubmit, env } = props;

  const envTitle = (env) => {
    switch (env) {
      case 'd':
        return 'Development';
      case 't':
        return 'Test';
      case 's':
        return 'Staging';
      case 'p':
        return 'Production';
      default:
        return 'Development';
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <Row>
        <Col md={12} lg={12} xs={12} sm={12}>
          <h2 style={{ textAlign: 'center', color: 'red' }}>{envTitle(env)}</h2>
          {env !== '' ? <hr /> : null}
        </Col>
      </Row>

      <Row className="mb-3">
        <Col md={4} lg={4} xs={12} sm={12}>
          <FormField
            id="first-name"
            name="first_name"
            placeholder="First Name"
            inputType="text"
            req={true}
          />
        </Col>
        <Col md={4} lg={4} xs={12} sm={12}>
          <FormField
            id="last-name"
            name="last_name"
            placeholder="Last Name"
            inputType="text"
            req={true}
          />
        </Col>
        <Col md={4} lg={4} xs={12} sm={12}>
          <FormField
            id="company-name"
            name="company_name"
            placeholder="Company Name"
            inputType="text"
            req={false}
          />
        </Col>
      </Row>
      <Row className="mb-3">
        <Col md={4} lg={4} xs={12} sm={12}>
          <FormField
            id="contact-id"
            name="contact_id"
            placeholder="Contact ID"
            inputType="text"
            req={false}
          />
        </Col>
        <Col md={4} lg={4} xs={12} sm={12}>
          <FormField
            id="contact-source"
            name="contact_source"
            placeholder="Contact Source"
            inputType="text"
            req={false}
          />
        </Col>

        <Col md={12} lg={12} xs={12} sm={12}>
          <hr />
        </Col>
        <Col md={12} lg={12} xs={12} sm={12}>
          <div className="form-group">
            <button type="submit" className="btn btn-primary">
              Send
            </button>
          </div>
        </Col>
      </Row>
    </form>
  );
};

export default reduxForm({ form: 'ArtistForm' })(ArtistForm);
