import axios from 'axios';

import {
  LOADING,
  SHOW,
  LOGIN,
  LOGIN_ERROR,
  LOGOUT,
  ALL_ITEMS,
  ALL_ITEMS_ERROR,
  ADD_ITEM,
  ADD_ITEM_ERROR,
  ALL_USERS,
  ALL_USERS_ERROR,
  USER_ITEMS,
  USER_ITEMS_ERROR,
  ALL_PURCHASES,
  ALL_PURCHASES_ERROR,
  GET_ARTISTS,
  GET_ARTISTS_ERROR,
  ADD_ARTIST,
  ALL_IPFS_FILES,
  GET_ARTIST,
  SET_ENV,
  GET_EDITIONS,
  GET_EDITIONS_ERROR,
  GET_APPS,
  GET_APPS_ERROR,
  GET_CHAINS,
  GET_CHAINS_ERROR,
  GET_NFT_CONTRACTS,
  GET_NFT_CONTRACTS_ERROR,
} from './actionTypes';
import Auth from '../components/Auth';

const _getEnv = (env) => {
  let URL = '';
  switch (env) {
    case 'd':
      URL = process.env.REACT_APP_URL_DEV;
      break;
    case 't':
      URL = process.env.REACT_APP_URL_TEST;
      break;
    case 's':
      URL = process.env.REACT_APP_URL_STAGING;
      break;
    case 'p':
      URL = process.env.REACT_APP_URL;
      break;
    default:
      URL = process.env.REACT_APP_URL;
      break;
  }
  return URL;
};

const _getEnvMint = (env) => {
  let URL = '';
  switch (env) {
    case 'd':
      URL = process.env.REACT_APP_MINT_SERVICE_DEV;
      break;
    case 't':
      URL = process.env.REACT_APP_MINT_SERVICE_TEST;
      break;
    case 's':
      URL = process.env.REACT_APP_MINT_SERVICE_STAGING;
      break;
    case 'p':
      URL = process.env.REACT_APP_MINT_SERVICE;
      break;
    default:
      URL = process.env.REACT_APP_MINT_SERVICE;
      break;
  }
  return URL;
};

const _getApiUrl = (api, env) => {
  if (api === 'e') {
    return _getEnv(env);
  }
  return _getEnvMint(env);
};

export const setEnv = async (env) => {
  const token = getToken();
  return async (dispatch, getState) => {
    const URL = _getApiUrl('e', env);
    dispatch({ type: LOADING, payload: true });
    dispatch([{ type: SET_ENV, payload: { env } }]);
    const apps = await axios.get(`${URL}/admin/get-apps`, {
      headers: { authorization: `${token}` },
    });
    dispatch([{ type: GET_APPS, payload: { apps: apps.data } }]);
    // const artists = await axios.get(`${URL}/item/get-artists-full`, {
    //   headers: { authorization: `${token}` },
    // });
    // dispatch([{ type: GET_ARTISTS, payload: { artists: artists.data.artists } }]);

    const artistsFmt = await axios.get(`${URL}/item/get-artists`, {
      headers: { authorization: `${token}` },
    });

    dispatch([{ type: GET_ARTISTS, payload: { artists: artistsFmt.data.artists } }]);

    const allItems = await axios.get(`${URL}/item/get-all-items`, {
      headers: { authorization: `${token}` },
    });

    dispatch([
      {
        type: ALL_ITEMS,
        payload: { items: typeof allItems.data === 'string' ? [] : allItems.data },
      },
    ]);

    const nftContracts = await axios.get(`${URL}/admin/get-nft-contracts`, {
      headers: { authorization: `${token}` },
    });

    dispatch([{ type: GET_NFT_CONTRACTS, payload: { nftContracts: nftContracts.data } }]);

    const chains = await axios.get(`${URL}/admin/get-chains`, {
      headers: { authorization: `${token}` },
    });

    dispatch([{ type: GET_CHAINS, payload: { chains: chains.data } }]);

    dispatch({ type: LOADING, payload: false });
  };
};

// Admin Actions
export const load = () => {
  return (dispatch) => {
    const AuthHelper = new Auth();
    if (AuthHelper.loggedIn()) {
      dispatch([{ type: LOGIN, payload: { isLoggedIn: true } }]);
    }
  };
};

export const login = (credentials) => {
  return (dispatch) => {
    dispatch({ type: LOADING, payload: true });
    axios
      .post(`${process.env.REACT_APP_URL}/admin/login`, credentials)
      .then((response) => {
        const auth = new Auth();
        auth.setToken(response.data.token, response.data.acl);
        dispatch({ type: LOGIN, payload: { isLoggedIn: true, acl: response.data.acl } });
      })
      .catch((error) => {
        console.log(error);
        dispatch({
          type: LOGIN_ERROR,
          payload: {
            show: 'login',
            message: `Error: ${error.response.data}`,
            alert: 'danger',
            loading: false,
          },
        });
      });
  };
};

export const logout = () => {
  return (dispatch) => {
    dispatch({ type: LOADING, payload: true });
    const AuthHelper = new Auth();
    AuthHelper.logout();
    dispatch({ type: LOGOUT, payload: { isLoggedIn: false, loading: false } });
  };
};

export const addArtist = (data) => {
  const token = getToken();
  return (dispatch, getState) => {
    const URL = _getApiUrl('e', getState().users.env);
    dispatch({ type: LOADING, payload: true });
    axios
      .post(`${URL}/item/add-artist`, { data }, { headers: { authorization: `${token}` } })
      .then((response) => {
        dispatch([
          {
            type: ADD_ARTIST,
            payload: {
              show: true,
              message: `Artist added succesfully`,
              alert: 'success',
            },
          },
        ]);
        dispatch({ type: LOADING, payload: false });
      })
      .catch((error) => {
        dispatch({
          type: ADD_ITEM_ERROR,
          payload: {
            show: true,
            message: `Error: ${error.response.data}`,
            alert: 'danger',
          },
        });
        dispatch({ type: LOADING, payload: false });
      });
  };
};

export const updateArtist = (data) => {
  const token = getToken();
  return (dispatch, getState) => {
    const URL = _getApiUrl('e', getState().users.env);
    dispatch({ type: LOADING, payload: true });
    axios
      .post(`${URL}/item/update-artist`, { data }, { headers: { authorization: `${token}` } })
      .then((response) => {
        dispatch([
          {
            type: ADD_ARTIST,
            payload: {
              show: true,
              message: `Artist updated succesfully`,
              alert: 'success',
            },
          },
        ]);
        dispatch(getArtists('p'));
        dispatch({ type: LOADING, payload: false });
      })
      .catch((error) => {
        dispatch({
          type: ADD_ITEM_ERROR,
          payload: {
            show: true,
            message: `Error: ${error.response.data}`,
            alert: 'danger',
          },
        });
        dispatch({ type: LOADING, payload: false });
      });
  };
};

export const setArtist = (artist) => {
  return (dispatch) => {
    dispatch([
      {
        type: GET_ARTIST,
        payload: {
          artist,
        },
      },
    ]);
  };
};

export const getArtists = () => {
  const token = getToken();
  return (dispatch, getState) => {
    const URL = _getApiUrl('e', getState().users.env);

    dispatch({ type: LOADING, payload: true });
    axios
      .get(`${URL}/item/get-artists-full`, {
        headers: { authorization: `${token}` },
      })
      .then((response) => {
        dispatch([
          {
            type: GET_ARTISTS,
            payload: {
              artists: response.data.artists,
            },
          },
        ]);
        dispatch({ type: LOADING, payload: false });
      })
      .catch((error) => {
        dispatch({
          type: GET_ARTISTS_ERROR,
          payload: {
            show: 'getArtists',
            message: `Error: ${error.response.data}`,
            alert: 'danger',
          },
        });
        dispatch({ type: LOADING, payload: false });
      });
  };
};

export const getArtistsFmt = () => {
  const token = getToken();
  return (dispatch, getState) => {
    const URL = _getApiUrl('e', getState().users.env);

    dispatch({ type: LOADING, payload: true });
    axios
      .get(`${URL}/item/get-artists`, {
        headers: { authorization: `${token}` },
      })
      .then((response) => {
        dispatch([
          {
            type: GET_ARTISTS,
            payload: {
              artists: response.data.artists,
            },
          },
        ]);
        dispatch({ type: LOADING, payload: false });
      })
      .catch((error) => {
        dispatch({
          type: GET_ARTISTS_ERROR,
          payload: {
            show: 'getArtists',
            message: `Error: ${error.response.data}`,
            alert: 'danger',
          },
        });
        dispatch({ type: LOADING, payload: false });
      });
  };
};

export const getApps = () => {
  const token = getToken();
  return (dispatch, getState) => {
    const URL = _getApiUrl('e', getState().users.env);

    dispatch({ type: LOADING, payload: true });
    axios
      .get(`${URL}/admin/get-apps`, {
        headers: { authorization: `${token}` },
      })
      .then((response) => {
        dispatch([
          {
            type: GET_APPS,
            payload: {
              apps: response.data,
            },
          },
        ]);
        dispatch({ type: LOADING, payload: false });
      })
      .catch((error) => {
        dispatch({
          type: GET_APPS_ERROR,
          payload: {
            show: 'getApps',
            message: `Error: ${error.response.data}`,
            alert: 'danger',
          },
        });
        dispatch({ type: LOADING, payload: false });
      });
  };
};

export const getChains = () => {
  const token = getToken();
  return (dispatch, getState) => {
    const URL = _getApiUrl('e', getState().users.env);

    dispatch({ type: LOADING, payload: true });
    axios
      .get(`${URL}/admin/get-chains`, {
        headers: { authorization: `${token}` },
      })
      .then((response) => {
        dispatch([
          {
            type: GET_CHAINS,
            payload: {
              chains: response.data,
            },
          },
        ]);
        dispatch({ type: LOADING, payload: false });
      })
      .catch((error) => {
        dispatch({
          type: GET_CHAINS_ERROR,
          payload: {
            show: 'getChains',
            message: `Error: ${error.response.data}`,
            alert: 'danger',
          },
        });
        dispatch({ type: LOADING, payload: false });
      });
  };
};

export const getNftContracts = () => {
  const token = getToken();
  return (dispatch, getState) => {
    const URL = _getApiUrl('e', getState().users.env);

    dispatch({ type: LOADING, payload: true });
    axios
      .get(`${URL}/admin/get-nft-contracts`, {
        headers: { authorization: `${token}` },
      })
      .then((response) => {
        dispatch([
          {
            type: GET_NFT_CONTRACTS,
            payload: {
              nftContracts: response.data,
            },
          },
        ]);
        dispatch({ type: LOADING, payload: false });
      })
      .catch((error) => {
        dispatch({
          type: GET_NFT_CONTRACTS_ERROR,
          payload: {
            show: 'getNftContracts',
            message: `Error: ${error.response.data}`,
            alert: 'danger',
          },
        });
        dispatch({ type: LOADING, payload: false });
      });
  };
};

export const getIpfsFiles = () => {
  const token = getToken();
  return (dispatch, getState) => {
    const URL = _getApiUrl('e', getState().users.env);

    dispatch({ type: LOADING, payload: true });
    axios
      .get(`${URL}/item/get-pins`, {
        headers: { authorization: `${token}` },
      })
      .then((response) => {
        dispatch([
          {
            type: ALL_IPFS_FILES,
            payload: {
              files: response.data,
            },
          },
        ]);
        dispatch({ type: LOADING, payload: false });
      })
      .catch((error) => {
        dispatch({
          type: GET_ARTISTS_ERROR,
          payload: {
            show: 'addEdition',
            message: `Error: ${error.response.data}`,
            alert: 'danger',
          },
        });
        dispatch({ type: LOADING, payload: false });
      });
  };
};

export const addItem = (data) => {
  const token = getToken();
  return (dispatch, getState) => {
    const URL = _getApiUrl('m', getState().users.env);

    dispatch({ type: LOADING, payload: true });
    axios
      .post(`${URL}`, { data }, { headers: { authorization: `${token}` } })
      .then((response) => {
        dispatch([
          {
            type: ADD_ITEM,
            payload: {
              show: true,
              message: `Transaction hash: ${response.data.success}`,
              alert: 'success',
            },
          },
        ]);
        dispatch({ type: LOADING, payload: false });
      })
      .catch((error) => {
        dispatch({
          type: ADD_ITEM_ERROR,
          payload: {
            show: true,
            message: `Error: ${error.response.data}`,
            alert: 'danger',
          },
        });
        dispatch({ type: LOADING, payload: false });
      });
  };
};

export const getEditions = (appId) => {
  const token = getToken();
  return (dispatch, getState) => {
    const URL = _getApiUrl('e', getState().users.env);

    dispatch({ type: LOADING, payload: true });
    axios
      .get(`${URL}/item/get-editions/${appId}`, {
        headers: { authorization: `${token}` },
      })
      .then((response) => {
        dispatch([
          {
            type: GET_EDITIONS,
            payload: {
              editions: response.data,
            },
          },
        ]);
        dispatch({ type: LOADING, payload: false });
      })
      .catch((error) => {
        dispatch({
          type: GET_EDITIONS_ERROR,
          payload: {
            show: 'addEdition',
            message: `Error: ${error.response.data}`,
            alert: 'danger',
          },
        });
        dispatch({ type: LOADING, payload: false });
      });
  };
};

export const allItems = () => {
  const token = getToken();
  return (dispatch, getState) => {
    const URL = _getApiUrl('e', getState().users.env);
    dispatch({ type: LOADING, payload: true });
    axios
      .get(`${URL}/item/get-all-items`, {
        headers: { authorization: `${token}` },
      })
      .then((response) => {
        dispatch({
          type: ALL_ITEMS,
          payload: {
            items: typeof response.data === 'string' ? [] : response.data,
          },
        });
        dispatch({ type: LOADING, payload: false });
      })
      .catch((error) => {
        dispatch({
          type: ALL_ITEMS_ERROR,
          payload: {
            show: 'allItems',
            message: `Error: ${error.message}`,
            alert: 'danger',
          },
        });
        dispatch({ type: LOADING, payload: false });
      });
  };
};

export const allUsers = () => {
  const token = getToken();
  return (dispatch, getState) => {
    const URL = _getApiUrl('e', getState().users.env);

    dispatch({ type: LOADING, payload: true });
    axios
      .get(`${URL}/user/get-users`, {
        headers: { authorization: `${token}` },
      })
      .then((response) => {
        dispatch({ type: ALL_USERS, payload: { usr: response.data } });
        dispatch({ type: LOADING, payload: false });
      })
      .catch((error) => {
        dispatch({ type: ALL_USERS_ERROR });
        dispatch({ type: LOADING, payload: false });
      });
  };
};

export const getUserItems = (addr) => {
  const token = getToken();
  return (dispatch, getState) => {
    const URL = _getApiUrl('e', getState().users.env);

    dispatch({ type: LOADING, payload: true });
    axios
      .get(`${URL}/user/get-items/${addr}`, {
        headers: { authorization: `${token}` },
      })
      .then((response) => {
        const tokens = [];
        for (let item of response.data) {
          tokens.push({ token_id: item });
        }
        dispatch({ type: USER_ITEMS, payload: { items: tokens, loading: false } });
      })
      .catch((error) => {
        dispatch({ type: USER_ITEMS_ERROR, payload: { loading: false } });
      });
  };
};

export const allPurchases = () => {
  const token = getToken();
  return (dispatch, getState) => {
    const URL = _getApiUrl('e', getState().users.env);
    dispatch({ type: LOADING, payload: true });
    axios
      .get(`${URL}/purchase/get-purchases`, {
        headers: { authorization: `${token}` },
      })
      .then((response) => {
        dispatch({ type: ALL_PURCHASES, payload: { purchases: response.data, loading: false } });
      })
      .catch((error) => {
        dispatch({ type: ALL_PURCHASES_ERROR, payload: { loading: false } });
      });
  };
};

export const dismissAlert = () => {
  return (dispatch) => {
    dispatch({ type: SHOW, payload: false });
  };
};

export const handleLoading = (loading) => {
  return (dispatch) => {
    dispatch({ type: LOADING, payload: loading });
  };
};

const getToken = () => {
  const AuthHelper = new Auth();
  return AuthHelper.getToken();
};
