import React, { Component } from 'react';
import { Row, Col } from 'react-bootstrap';
import { connect } from 'react-redux';
//import { bindActionCreators } from 'redux';

import Page from '../../layout/page';
import Spin from '../../components/Spin';

class ViewItem extends Component {
  constructor(props) {
    super(props);
    this.onDismiss = this.onDismiss.bind(this);
    this.toggle = this.toggle.bind(this);
    this.state = {
      item: {
        edition_name: '',
        description: '',
        token_price: 0,
        item_image_url: '',
        brand_company_name: '',
      },
      msg: '',
      isMsg: false,
      alert: '',
      loading: false,
      activeTab: '1',
    };
  }

  componentDidMount() {
    for (let item of this.props.items) {
      if (String(item.edition_id) === String(this.props.match.params.id)) {
        this.setState({ item });
        break;
      }
    }
  }

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  }

  onDismiss() {
    this.setState({ isMsg: false });
  }

  render() {
    if (this.state.loading) {
      return <Spin />;
    }
    return (
      <Page title={'Items'}>
        <Col md={12} lg={12} xs={12}>
          <div className="card">
            <div className="card-header">View Item</div>
            <div className="card-body">
              <Row>
                <Col md={4} lg={4} xs={12} sm={12}>
                  <div className="form-group">
                    <label>Name</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Name"
                      value={this.state.item.edition_name}
                      disabled
                    />
                  </div>
                </Col>
                <Col md={2} lg={2} xs={12} sm={12}>
                  <div className="form-group">
                    <label>Brand</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Brand"
                      value={this.state.item.brand_company_name}
                      disabled
                    />
                  </div>
                </Col>
                <Col md={2} lg={2} xs={12} sm={12}>
                  <div className="form-group">
                    <label>Price</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Price"
                      value={this.state.item.token_price}
                      disabled
                    />
                  </div>
                </Col>
                <Col md={4} lg={4} xs={12} sm={12}>
                  <div className="form-group">
                    <label>Image URI</label>
                    <input
                      type="url"
                      className="form-control"
                      placeholder="Image URI"
                      value={this.state.item.item_image_url}
                      disabled
                    />
                  </div>
                </Col>

                <Col md={12} lg={12} xs={12} sm={12}>
                  <div className="form-group">
                    <label>Description</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Description"
                      value={this.state.item.description}
                      disabled
                    />
                  </div>
                </Col>

                {/* <Col md={12} lg={12} xs={12} sm={12}><hr/></Col>
            <Col md={12} lg={12} xs={12} sm={12}>
                <div className="form-group">
                    <button type="submit" className="btn btn-primary">Send</button>
                </div>
            </Col> */}
              </Row>
            </div>
          </div>
          {/* <Nav tabs>
          <NavItem>
            <NavLink
              className={ (this.state.activeTab === '1') ? 'active' : null  }
              onClick={() => { this.toggle('1'); }}
            >
              Item
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={ (this.state.activeTab === '2') ? 'active' : null  }
              onClick={() => { this.toggle('2'); }}
            >
              Animais
            </NavLink>
          </NavItem>
        </Nav>
        <TabContent activeTab={this.state.activeTab} className='mt-20'>
          <TabPane tabId="1">
            <div className="card">
              <div className="card-body">
                
              </div>
            </div>
          </TabPane>
          <TabPane tabId="2">
            Animals
          </TabPane>
        </TabContent> */}
        </Col>
      </Page>
    );
  }
}

const mapStateToProps = (state) => ({
  loading: state.editions.loading,
  items: state.editions.items,
});
export default connect(mapStateToProps, null)(ViewItem);
