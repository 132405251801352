import { ALL_USERS, ALL_USERS_ERROR, USER_ITEMS, USER_ITEMS_ERROR, SET_ENV } from '../actionTypes';

const initialState = {
  usr: [],
  items: [],
  env: 'd',
};

export default function (state = initialState, action) {
  switch (action.type) {
    case ALL_USERS:
      return { ...state, usr: action.payload.usr };
    case ALL_USERS_ERROR:
      return { ...state };
    case USER_ITEMS:
      return { ...state, items: action.payload.items };
    case USER_ITEMS_ERROR:
      return { ...state };
    case SET_ENV:
      return { ...state, env: action.payload.env };
    default:
      return state;
  }
}
