import React, { Component } from "react";
import { Row, Col } from "reactstrap";
import { connect } from "react-redux";
import moment from "moment";

import Page from "../../layout/page";
import Spin from "../../components/Spin";

class ViewPurchase extends Component {
  constructor(props) {
    super(props);
    this.state = {
      purchase: {
        id: "",
        app_id: "",
        custody_user_id: "",
        edition_id: "",
        item_id: "",
        platform: "",
        status: "",
        createdAt: "",
        transaction_receipt: "",
        purchase_date: "",
        transaction_hash: "",
      },
    };
  }

  componentDidMount() {
    for (let purchase of this.props.purchases) {
      if (String(purchase.id) === String(this.props.match.params.id)) {
        this.setState({ purchase });
        break;
      }
    }
  }

  render() {
    if (this.props.loading) {
      return <Spin />;
    }
    return (
      <Page title={"Purchases"}>
        <Col md={12} lg={12} xs={12}>
          <div className="card">
            <div className="card-header">Purchase</div>
            <div className="card-body">
              <Row>
                <Col md={2} lg={2} xs={12} sm={12}>
                  <div className="form-group">
                    <label>Id</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Id"
                      value={this.state.purchase.id}
                      disabled
                    />
                  </div>
                </Col>
                <Col md={2} lg={2} xs={12} sm={12}>
                  <div className="form-group">
                    <label>App Id</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="App Id"
                      value={this.state.purchase.app_id}
                      disabled
                    />
                  </div>
                </Col>
                <Col md={2} lg={2} xs={12} sm={12}>
                  <div className="form-group">
                    <label>Edition Id</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Edition Id"
                      value={this.state.purchase.edition_id}
                      disabled
                    />
                  </div>
                </Col>
                <Col md={2} lg={2} xs={12} sm={12}>
                  <div className="form-group">
                    <label>Item Id</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Item Id"
                      value={this.state.purchase.item_id}
                      disabled
                    />
                  </div>
                </Col>
                <Col md={4} lg={4} xs={12} sm={12}>
                  <div className="form-group">
                    <label>Purchase date (mined)</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Purchase date"
                      value={moment(this.state.purchase.purchase_date).format(
                        "dddd, MMMM Do YYYY, h:mm:ss a"
                      )}
                      disabled
                    />
                  </div>
                </Col>
                <Col md={1} lg={1} xs={12} sm={12}>
                  <div className="form-group">
                    <label>Platform</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Platform"
                      value={this.state.purchase.platform}
                      disabled
                    />
                  </div>
                </Col>
                <Col md={2} lg={2} xs={12} sm={12}>
                  <div className="form-group">
                    <label>Status</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Status"
                      value={this.state.purchase.status}
                      disabled
                    />
                  </div>
                </Col>
                <Col md={6} lg={6} xs={12} sm={12}>
                  <div className="form-group">
                    <label>Transaction Hash</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Transaction Hash"
                      value={this.state.purchase.transaction_hash}
                      disabled
                    />
                  </div>
                </Col>
                <Col md={3} lg={3} xs={12} sm={12}>
                  <div className="form-group">
                    <label>Created At</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Created At"
                      value={moment(this.state.purchase.createdAt).format(
                        "dddd, MMMM Do YYYY, h:mm:ss a"
                      )}
                      disabled
                    />
                  </div>
                </Col>
                <Col md={12} lg={12} xs={12} sm={12}>
                  <div className="form-group">
                    <label>Transaction Receipt</label>
                    <textarea
                      rows="15"
                      className="form-control"
                      placeholder="Transaction Receipt"
                      value={this.state.purchase.transaction_receipt}
                      disabled
                    />
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </Col>
      </Page>
    );
  }
}

const mapStateToProps = state => ({
  loading: state.purchases.loading,
  purchases: state.purchases.purchases,
});

export default connect(mapStateToProps, null)(ViewPurchase);
