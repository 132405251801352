export const LOADING = 'LOADING';
export const SHOW = 'SHOW';
export const LOGOUT = 'LOGOUT';
export const LOGIN = 'LOGIN';
export const LOGIN_ERROR = 'LOGIN_ERROR';
export const ALL_ITEMS = 'ALL_ITEMS';
export const ALL_ITEMS_ERROR = 'ALL_ITEMS_ERROR';
export const ADD_ITEM = 'ADD_ITEM';
export const ADD_ITEM_ERROR = 'ADD_ITEM_ERROR';
export const ALL_USERS = 'ALL_USERS';
export const ALL_USERS_ERROR = 'ALL_USEALL_USERS_ERROR';
export const USER_ITEMS = 'USER_ITEMS';
export const USER_ITEMS_ERROR = 'USER_ITEMS_ERROR';
export const ALL_PURCHASES = 'ALL_PURCHASES';
export const ALL_PURCHASES_ERROR = 'ALL_PURCHASES_ERROR';
export const GET_ARTISTS = 'GET_ARTISTS';
export const GET_ARTIST = 'GET_ARTIST';
export const GET_ARTISTS_ERROR = 'GET_ARTISTS_ERROR';
export const ADD_ARTIST = 'ADD_ARTIST';
export const ADD_ARTIST_ERROR = 'ADD_ARTIST_ERROR';
export const ALL_IPFS_FILES = 'ALL_IPFS_FILES';
export const ALL_IPFS_FILES_ERROR = 'ALL_IPFS_FILES_ERROR';
export const SET_ENV = 'SET_ENV';
export const GET_EDITIONS = 'GET_EDITIONS';
export const GET_EDITIONS_ERROR = 'GET_EDITIONS_ERROR';
export const GET_APPS = 'GET_APPS';
export const GET_APPS_ERROR = 'GET_APPS_ERROR';
export const GET_NFT_CONTRACTS = 'GET_NFT_CONTRACTS';
export const GET_NFT_CONTRACTS_ERROR = 'GET_NFT_CONTRACTS_ERROR';
export const GET_CHAINS = 'GET_CHAINS';
export const GET_CHAINS_ERROR = 'GET_CHAINS_ERROR';
