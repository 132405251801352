import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import { logout, setEnv } from '../redux/actions';

class Header extends Component {
  aclOptions() {
    // if (this.props.acl === 1) {
    //   return (
    //     <>
    //       <option value="d">Development</option>
    //       <option value="t">Test</option>
    //       <option value="s">Staging</option>
    //       <option value="p">Production</option>
    //     </>
    //   );
    // }
    return (
      <>
        <option value="d">Development</option>
        <option value="t">Test</option>
        <option value="s">Staging</option>
        <option value="p">Production</option>
      </>
    );
  }

  logout = () => {
    return this.props.logout();
  };

  render() {
    return (
      <nav className="navbar navbar-dark sticky-top bg-dark flex-md-nowrap p-0 shadow">
        <Link to="/items/add-item" className="navbar-brand col-md-3 col-lg-2 me-0 px-3">
          <img
            className="img-fluid"
            src={require('../assets/img/logo_epik.png')}
            alt="Epik"
            width="25%"
          />
        </Link>
        <ul className="navbar-nav px-3">
          <li className="nav-item text-nowrap">
            <form>
              <select
                className=""
                style={{ fontSize: 12 }}
                onChange={(e) => this.props.setEnv(e.target.value, this.props.match.path)}
                value={this.props.env}
              >
                {this.aclOptions()}
              </select>
            </form>
          </li>
        </ul>
        <ul className="navbar-nav px-3">
          <li className="nav-item text-nowrap">
            <button
              title="Logout"
              type="button"
              onClick={() => this.logout()}
              style={{
                fontWeight: 'normal',
                background: 'transparent',
                border: 'none',
                color: '#FFF',
              }}
            >
              <FontAwesomeIcon size="lg" icon={faSignOutAlt} />
            </button>
          </li>
        </ul>
      </nav>
    );
  }
}

const mapStateToProps = (state) => ({
  env: state.users.env,
});
const mapDispatchToProps = (dispatch) => bindActionCreators({ logout, setEnv }, dispatch);
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Header));
