import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';

import admin from './admin';
import editions from './editions';
import users from './users';
import purchases from './purchases';
import artists from './artists';

export default combineReducers({ admin, editions, users, artists, purchases, form: formReducer });
