import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { Container, Row } from 'reactstrap';
import { connect } from 'react-redux';

import Header from './header';
import Sidebar from './sidebar';

const Main = ({ component: Component, ...rest }) => {
  const { isLoggedIn, acl } = rest;

  return (
    <Route
      {...rest}
      render={(matchProps) =>
        isLoggedIn ? (
          <>
            <Header acl={acl} />
            <Container fluid={true}>
              <Row>
                <Sidebar />
                <Component {...matchProps} />
              </Row>
            </Container>
          </>
        ) : (
          <Redirect to="/" />
        )
      }
    />
  );
};

const mapStateToProps = (state) => ({ isLoggedIn: state.admin.isLoggedIn, acl: state.admin.acl });
export default connect(mapStateToProps)(Main);
