import React, { Component } from 'react';
import { Col, Alert } from 'react-bootstrap';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { addArtist, dismissAlert, handleLoading } from '../../redux/actions';
import Page from '../../layout/page';
import Spin from '../../components/Spin';
import ArtistForm from '../../components/ArtistForm';

class AddArtist extends Component {
  constructor(props) {
    super(props);
    this.onDismiss = this.onDismiss.bind(this);
  }

  componentDidMount() {
    return this.props.handleLoading(false);
  }

  onDismiss() {
    return this.props.dismissAlert();
  }

  alertDismissible() {
    if (this.props.show) {
      return (
        <Alert variant={this.props.alert} onClose={this.onDismiss} dismissible>
          {this.props.message}
        </Alert>
      );
    }
    return null;
  }

  render() {
    if (this.props.loading) {
      return <Spin />;
    }
    return (
      <Page title={'Artists'}>
        <Col md={12} lg={12} xs={12}>
          <div className="card">
            <div className="card-header">Add Artist</div>
            <div className="card-body">
              <ArtistForm onSubmit={this.props.addArtist} env={this.props.env} />
              {this.alertDismissible()}
            </div>
          </div>
        </Col>
      </Page>
    );
  }
}
const mapStateToProps = (state) => ({
  loading: state.artists.loading,
  show: state.artists.show,
  message: state.artists.message,
  alert: state.artists.alert,
  env: state.users.env,
});
const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ addArtist, dismissAlert, handleLoading }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(AddArtist);
