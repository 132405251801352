import React, { Component } from 'react';
import { Col, Alert, FormGroup, Row, Input, FormText, Label } from 'reactstrap';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
// import { create } from 'ipfs-http-client';

import { dismissAlert, handleLoading } from '../../redux/actions';
import Page from '../../layout/page';
import Spin from '../../components/Spin';
import axios from 'axios';

class UploadFile extends Component {
  constructor(props) {
    super(props);
    this.onDismiss = this.onDismiss.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.onChange = this.onChange.bind(this);
    this.state = { fileData: '', fileUrl: '' };
  }

  // changeHandler = event => {
  //   const field = event.target.name;
  //   const add = this.state.add;
  //   add[field] = event.target.value;
  //   return this.setState({add});
  // }
  async handleSubmit() {
    try {
      this.props.handleLoading(true);
      const url = `https://api.pinata.cloud/pinning/pinFileToIPFS`;

      let data = new FormData();
      data.append('file', this.state.fileData);

      const metadata = JSON.stringify({
        name: this.state.fileData.name,
      });

      data.append('pinataMetadata', metadata);

      const response = await axios.post(url, data, {
        maxBodyLength: 'Infinity',
        headers: {
          'Content-Type': `multipart/form-data; boundary=${data._boundary}`,
          Authorization: `Bearer ${process.env.REACT_APP_PINATA_BEARER}`,
        },
      });
      this.setState({
        fileUrl: `https://ipfs.epikprime.com/ipfs/${response.data.IpfsHash}`,
      });
      this.props.handleLoading(false);
    } catch (error) {
      console.log(error);
    }
  }

  async onChange(e) {
    const file = e.target.files[0];
    this.setState({
      fileData: file,
    });
  }

  componentDidMount() {
    return this.props.handleLoading(false);
  }

  onDismiss() {
    return this.props.dismissAlert();
  }

  render() {
    //console.log(this.props)
    if (this.props.loading) {
      return <Spin />;
    }
    return (
      <Page title={'Upload File to IPFS'}>
        <Col md={12} lg={12} xs={12}>
          <div className="card">
            <div className="card-header">Upload File</div>
            <div className="card-body">
              <form onSubmit={this.handleSubmit}>
                <Row>
                  <Col md={12} lg={12} xs={12} sm={12}>
                    <FormGroup>
                      <Label for="exampleFile">File</Label>
                      <Input type="file" name="ipfs_file" id="ipfs_file" onChange={this.onChange} />
                      <FormText color="muted">
                        Copy the URL below for future reference of the file. This URL will not be
                        saved.
                      </FormText>
                    </FormGroup>
                  </Col>
                  <Col md={12} lg={12} xs={12} sm={12}>
                    <FormGroup>
                      <Label for="exampleFile">File URL</Label>
                      <Input type="text" value={this.state.fileUrl} disabled />
                    </FormGroup>
                  </Col>
                  <Col md={12} lg={12} xs={12} sm={12}>
                    <div className="form-group">
                      <button type="button" onClick={this.handleSubmit} className="btn btn-primary">
                        Upload
                      </button>
                    </div>
                  </Col>
                </Row>
              </form>

              <Alert
                color={this.props.alert}
                isOpen={this.props.show === 'addItem'}
                toggle={this.onDismiss}
              >
                {this.props.message}
              </Alert>
            </div>
          </div>
        </Col>
      </Page>
    );
  }
}
const mapStateToProps = (state) => ({
  loading: state.editions.loading,
  show: state.editions.show,
  message: state.editions.message,
  alert: state.editions.alert,
});
const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ dismissAlert, handleLoading }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(UploadFile);
