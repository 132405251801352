import React from 'react';
import { Field } from 'redux-form';
import { createTextMask } from 'redux-form-input-masks';

const FormField = (props) => {
  const { id, name, placeholder, inputType, req } = props;
  return (
    <div className="form-group">
      <label htmlFor={id}>{placeholder}</label>
      <Field
        component="input"
        type={inputType}
        className="form-control"
        id={id}
        name={name}
        placeholder={placeholder}
        required={req}
      />
    </div>
  );
};

const TextArea = (props) => {
  const { id, name, placeholder, req } = props;
  return (
    <div className="form-group">
      <label htmlFor={id}>{placeholder}</label>
      <Field
        component="textarea"
        className="form-control"
        id={id}
        name={name}
        placeholder={placeholder}
        props={{ rows: 5 }}
        required={req}
      />
    </div>
  );
};

const Select = (props) => {
  const {
    id,
    name,
    placeholder,
    options = [],
    optionsLabels = [],
    req = false,
    onChange = null,
  } = props;
  // const capitalize = (str) => {
  //   return str[0].toUpperCase() + str.slice(1).toLowerCase();
  // };
  const opt = options.map((option, i) => {
    return (
      <option key={i} value={option}>
        {optionsLabels[i]}
      </option>
    );
  });

  return (
    <div className="form-group">
      <label htmlFor={id}>{placeholder}</label>
      <Field
        name={name}
        component="select"
        className="form-control"
        required={req}
        onChange={onChange}
      >
        <option value="" selected disabled hidden>
          Choose here
        </option>
        {opt}
      </Field>
    </div>
  );
};

const SelectEnv = (props) => {
  const { id, name, placeholder, options = [], optionsLabels = [], req, handleEvent } = props;
  // const capitalize = (str) => {
  //   return str[0].toUpperCase() + str.slice(1).toLowerCase();
  // };
  const opt = options.map((option, i) => {
    return (
      <option key={i} value={option}>
        {optionsLabels[i]}
      </option>
    );
  });

  return (
    <div className="form-group">
      <label htmlFor={id}>{placeholder}</label>
      <Field
        name={name}
        component="select"
        className="form-control"
        required={req}
        onChange={(e) => handleEvent(e.target.value)}
      >
        <option selected disabled hidden>
          Choose here
        </option>
        {opt}
      </Field>
    </div>
  );
};

const FormFieldMask = (props) => {
  const { id, name, placeholder, type, maskType, req } = props;
  return (
    <div className="form-group">
      <label htmlFor={id}>{placeholder}</label>
      <Field
        component="input"
        type={type}
        className="form-control"
        id={id}
        name={name}
        placeholder={placeholder}
        {...createTextMask({ pattern: maskType })}
        required={req}
      />
    </div>
  );
};

export { FormField, FormFieldMask, TextArea, Select, SelectEnv };
