import { ALL_PURCHASES, ALL_PURCHASES_ERROR } from '../actionTypes';

const initialState = {
  purchases: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case ALL_PURCHASES:
      return { ...state, purchases: action.payload.purchases };
    case ALL_PURCHASES_ERROR:
      return {
        ...state,
        purchases: action.payload.purchases,
        show: action.payload.show,
        message: action.payload.message,
        alert: action.payload.alert,
      };
    default:
      return state;
  }
}
