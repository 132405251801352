import {
  SHOW,
  ADD_ARTIST,
  ADD_ARTIST_ERROR,
  GET_ARTIST,
  GET_ARTISTS,
  GET_ARTISTS_ERROR,
} from '../actionTypes';

const initialState = {
  show: false,
  alert: '',
  message: '',
  artists: [],
  artist: {},
};

export default function (state = initialState, action) {
  switch (action.type) {
    case ADD_ARTIST:
      return {
        ...state,
        show: action.payload.show,
        message: action.payload.message,
        alert: action.payload.alert,
      };
    case ADD_ARTIST_ERROR:
      return {
        ...state,
        show: action.payload.show,
        message: action.payload.message,
        alert: action.payload.alert,
      };
    case GET_ARTISTS:
      return { ...state, artists: action.payload.artists };
    case GET_ARTIST:
      return { ...state, artist: action.payload.artist };
    case GET_ARTISTS_ERROR:
      return {
        ...state,
        show: action.payload.show,
        message: action.payload.message,
        alert: action.payload.alert,
        artists: [],
      };
    case SHOW:
      return { ...state, show: action.payload };
    default:
      return state;
  }
}
