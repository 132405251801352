import React, { Component } from 'react';
import { Col, Alert } from 'react-bootstrap';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import {
  addItem,
  getArtistsFmt,
  dismissAlert,
  handleLoading,
  getEditions,
  getApps,
  getChains,
  getNftContracts,
  setEnv,
} from '../../redux/actions';
import Page from '../../layout/page';
import Spin from '../../components/Spin';
import ItemForm from '../../components/ItemForm';

class AddItem extends Component {
  constructor(props) {
    super(props);
    this.props.setEnv(this.props.env);
    this.onDismiss = this.onDismiss.bind(this);
    this.handleApp = this.handleApp.bind(this);
  }

  onDismiss() {
    return this.props.dismissAlert();
  }

  handleApp(appId) {
    this.props.getEditions(appId);
  }

  alertDismissible() {
    if (this.props.show) {
      return (
        <Alert variant={this.props.alert} onClose={this.onDismiss} dismissible>
          {this.props.message}
        </Alert>
      );
    }
    return null;
  }

  render() {
    if (this.props.loading) {
      return <Spin />;
    }
    return (
      <Page title={'Items'}>
        <Col md={12} lg={12} xs={12}>
          <div className="card">
            <div className="card-header">Add Item</div>
            <div className="card-body">
              <ItemForm
                onSubmit={this.props.addItem}
                artists={this.props.artists}
                editions={this.props.editions}
                apps={this.props.apps}
                chains={this.props.chains}
                nftContracts={this.props.nftContracts}
                handleApp={this.handleApp}
                env={this.props.env}
              />
              {this.alertDismissible()}
            </div>
          </div>
        </Col>
      </Page>
    );
  }
}
const mapStateToProps = (state) => ({
  loading: state.editions.loading,
  show: state.editions.show,
  message: state.editions.message,
  alert: state.editions.alert,
  artists: state.artists.artists,
  env: state.users.env,
  editions: state.editions.editions,
  apps: state.admin.apps,
  chains: state.admin.chains,
  nftContracts: state.admin.nftContracts,
});
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      addItem,
      getArtistsFmt,
      dismissAlert,
      handleLoading,
      getEditions,
      getApps,
      getChains,
      getNftContracts,
      setEnv,
    },
    dispatch,
  );
export default connect(mapStateToProps, mapDispatchToProps)(AddItem);
