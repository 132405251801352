import React, { Component } from 'react';
import { Nav } from 'react-bootstrap';
import { NavLink, withRouter } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faList } from '@fortawesome/free-solid-svg-icons';

class Sidebar extends Component {
  render() {
    return (
      <Nav className="col-md-3 col-lg-2 d-md-block bg-light sidebar collapse">
        <div className="position-sticky pt-3">
          <ul className="nav flex-column flex-nowrap">
            <NavLink activeClassName="active" exact to="/items/all-items" className="nav-link">
              <FontAwesomeIcon icon={faList} /> All Items
            </NavLink>
            <NavLink activeClassName="active" exact to="/items/add-item" className="nav-link">
              <FontAwesomeIcon icon={faPlus} /> Add Item
            </NavLink>
            {/* <NavLink activeClassName="active" exact to="/users/all-users" className="nav-link">
              <FontAwesomeIcon icon={faUsers} /> All Users
            </NavLink> */}
            <NavLink activeClassName="active" exact to="/artists/all-artists" className="nav-link">
              <FontAwesomeIcon icon={faList} /> All Artists
            </NavLink>
            <NavLink activeClassName="active" exact to="/artists/add-artist" className="nav-link">
              <FontAwesomeIcon icon={faPlus} /> Add Artist
            </NavLink>
            {/* <NavLink
              activeClassName="active"
              exact
              to="/purchases/all-purchases"
              className="nav-link"
            >
              <FontAwesomeIcon icon={faShoppingBag} /> All Purchases
            </NavLink>
            <NavLink activeClassName="active" exact to="/ipfs/upload-file" className="nav-link">
              <FontAwesomeIcon icon={faUpload} /> Upload File
            </NavLink>
            <NavLink activeClassName="active" exact to="/ipfs/upload-data" className="nav-link">
              <FontAwesomeIcon icon={faUpload} /> Upload Text Data
            </NavLink>
            <NavLink activeClassName="active" exact to="/ipfs/all-files" className="nav-link">
              <FontAwesomeIcon icon={faList} /> View Files
            </NavLink> */}
          </ul>
        </div>
      </Nav>
    );
  }
}

export default withRouter(Sidebar);
