import React from 'react';
import { Row, Col } from 'reactstrap';
import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';

import { FormField } from './FormField';

let UpdateArtistForm = (props) => {
  const { handleSubmit } = props;

  return (
    <form onSubmit={handleSubmit}>
      <Row className="mb-3">
        <Col md={4} lg={4} xs={12} sm={12}>
          <FormField
            id="first-name"
            name="first_name"
            placeholder="First Name"
            inputType="text"
            req={true}
          />
        </Col>
        <Col md={4} lg={4} xs={12} sm={12}>
          <FormField
            id="last-name"
            name="last_name"
            placeholder="Last Name"
            inputType="text"
            req={true}
          />
        </Col>
        <Col md={4} lg={4} xs={12} sm={12}>
          <FormField
            id="company-name"
            name="company_name"
            placeholder="Company Name"
            inputType="text"
            req={false}
          />
        </Col>
      </Row>
      <Row className="mb-3">
        <Col md={4} lg={4} xs={12} sm={12}>
          <FormField
            id="contact-id"
            name="contact_id"
            placeholder="Contact ID"
            inputType="text"
            req={false}
          />
        </Col>
        <Col md={4} lg={4} xs={12} sm={12}>
          <FormField
            id="contact-source"
            name="contact_source"
            placeholder="Contact Source"
            inputType="text"
            req={false}
          />
        </Col>

        <Col md={12} lg={12} xs={12} sm={12}>
          <hr />
        </Col>
        <Col md={12} lg={12} xs={12} sm={12}>
          <div className="form-group">
            <button type="submit" className="btn btn-primary">
              Update
            </button>
          </div>
        </Col>
      </Row>
    </form>
  );
};

const mapStateToProps = (state) => {
  return {
    initialValues: state.artists.artist,
  };
};

export default connect(mapStateToProps)(
  reduxForm({ form: 'UpdateArtistForm', enableReinitialize: true })(UpdateArtistForm),
);
