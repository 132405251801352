import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { load } from './redux/actions';
import Main from './layout/main';
// import Items from './pages/items/Items';
// import ViewItem from './pages/items/ViewItem';
// import AddItem from './pages/items/AddItem';
import Items from './pages/items/Items';
import ViewItem from './pages/items/ViewItem';
import AddItem from './pages/items/AddItem';
import Artists from './pages/artists/Artists';
import ViewArtist from './pages/artists/ViewArtist';
import AddArtist from './pages/artists/AddArtist';
import Users from './pages/users/Users';
import ViewUser from './pages/users/ViewUser';
import Login from './pages/login/Login';
import Purchases from './pages/purchases/Purchases';
import ViewPurchase from './pages/purchases/ViewPurchase';
import UploadFile from './pages/ipfs/UploadFile';
import UploadData from './pages/ipfs/UploadData';
import ListData from './pages/ipfs/List';

import 'bootstrap/dist/js/bootstrap.min.js';
import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/css/layout.css';

const App = (props) => {
  props.load();

  return (
    <BrowserRouter basename="/">
      <Switch>
        <Route exact={true} path="/" component={Login} />
        {/* <Main path="/items/all-items" component={Items} />
        <Main path="/items/item/:id" component={ViewItem} />
        <Main path="/items/add-item" component={AddItem} /> */}
        <Main path="/items/all-items" component={Items} />
        <Main path="/items/item/:id" component={ViewItem} />
        <Main path="/items/add-item" component={AddItem} />
        <Main path="/artists/all-artists" component={Artists} />
        <Main path="/artists/artist/:id" component={ViewArtist} />
        <Main path="/artists/add-artist" component={AddArtist} />
        <Main path="/users/all-users" component={Users} />
        <Main path="/users/user/:id" component={ViewUser} />
        <Main path="/purchases/all-purchases" component={Purchases} />
        <Main path="/purchases/purchase/:id" component={ViewPurchase} />
        <Main path="/ipfs/upload-file" component={UploadFile} />
        <Main path="/ipfs/upload-data" component={UploadData} />
        <Main path="/ipfs/all-files" component={ListData} />
      </Switch>
    </BrowserRouter>
  );
};

const mapDispatchToProps = (dispatch) => bindActionCreators({ load }, dispatch);

export default connect(null, mapDispatchToProps)(App);
