import React, { Component } from 'react';
import { Col, Alert } from 'react-bootstrap';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getArtists, updateArtist, setArtist, dismissAlert } from '../../redux/actions';

import Page from '../../layout/page';
import Spin from '../../components/Spin';
import UpdateArtistForm from '../../components/UpdateArtistForm';

class ViewArtist extends Component {
  constructor(props) {
    super(props);
    this.onDismiss = this.onDismiss.bind(this);
  }
  componentDidMount() {
    if (this.props.artists.length > 0) {
      for (let artist of this.props.artists) {
        if (String(artist.id) === String(this.props.match.params.id)) {
          this.props.setArtist(artist);
          break;
        }
      }
    } else {
      this.props.getArtists('p');
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.artists !== this.props.artists) {
      for (let artist of this.props.artists) {
        if (String(artist.id) === String(this.props.match.params.id)) {
          this.props.setArtist(artist);
          break;
        }
      }
    }
  }

  onDismiss() {
    return this.props.dismissAlert();
  }

  alertDismissible() {
    if (this.props.show) {
      return (
        <Alert variant={this.props.alert} onClose={this.onDismiss} dismissible>
          {this.props.message}
        </Alert>
      );
    }
    return null;
  }

  render() {
    if (this.props.loading) {
      return <Spin />;
    }

    return (
      <Page title={'Artists'}>
        <Col md={12} lg={12} xs={12}>
          <div className="card">
            <div className="card-header">View Artist</div>
            <div className="card-body">
              {/* <form onSubmit={this.handleSubmit}>
                <Row>
                  <Col md={4} lg={4} xs={12} sm={12}>
                    <div className="form-group">
                      <label>First Name</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="First Name"
                        defaultValue={this.state.artist.first_name}
                      />
                    </div>
                  </Col>
                  <Col md={2} lg={2} xs={12} sm={12}>
                    <div className="form-group">
                      <label>Last Name</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Last Name"
                        defaultValue={this.state.artist.last_name}
                      />
                    </div>
                  </Col>
                  <Col md={2} lg={2} xs={12} sm={12}>
                    <div className="form-group">
                      <label>Company</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Company"
                        defaultValue={this.state.artist.company_name}
                      />
                    </div>
                  </Col>
                  <Col md={4} lg={4} xs={12} sm={12}>
                    <div className="form-group">
                      <label>Contact ID</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Contact ID"
                        defaultValue={this.state.artist.contact_id}
                      />
                    </div>
                  </Col>

                  <Col md={12} lg={12} xs={12} sm={12}>
                    <div className="form-group">
                      <label>Contact Source</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Contact Source"
                        defaultValue={this.state.artist.contact_source}
                      />
                    </div>
                  </Col>

                  <Col md={12} lg={12} xs={12} sm={12}>
                    <hr />
                  </Col>
                  <Col md={12} lg={12} xs={12} sm={12}>
                    <div className="form-group">
                      <button type="submit" className="btn btn-primary">
                        Update
                      </button>
                    </div>
                  </Col>
                </Row>
              </form> */}
              <UpdateArtistForm onSubmit={this.props.updateArtist} artists={this.props.artists} />
              {this.alertDismissible()}
            </div>
          </div>
        </Col>
      </Page>
    );
  }
}

const mapStateToProps = (state) => ({
  loading: state.artists.loading,
  alert: state.artists.alert,
  message: state.artists.message,
  artists: state.artists.artists,
  artist: state.artists.artist,
  show: state.artists.show,
});
const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ getArtists, updateArtist, setArtist, dismissAlert }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(ViewArtist);
