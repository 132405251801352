import React, { useState, useEffect } from 'react';
import { Row, Col } from 'reactstrap';
import { reduxForm, Field } from 'redux-form';
import { WithContext as ReactTags } from 'react-tag-input';
import 'react-alert-confirm/dist/index.css';
import alertConfirm from 'react-alert-confirm';

import '../assets/css/react-tags.css';

import { FormField, TextArea, Select } from './FormField';

const ItemForm = (props) => {
  const { handleSubmit, artists, editions, env, apps, chains, nftContracts } = props;
  const [tagsArtists, setTagsArtists] = useState([]);
  const [tagsEditions, setTagsEditions] = useState([]);
  const [artistsSuggestions, setArtistsSuggestions] = useState([]);
  const [editionsSuggestions, setEditionsSuggestions] = useState([]);
  const [isCombineProxyWallet, setIsCombineProxyWallet] = useState(false);
  const [createProxyWallet, setCreateProxyWallet] = useState(true);
  const [metadataInfo, setMetadataInfo] = useState(false);
  const [tagsApps, setTagApps] = useState([]);
  const [tagsChains, setTagChains] = useState([]);
  const [tagsNftContracts, setTagNFtContracts] = useState([]);

  useEffect(() => {
    setArtistsSuggestions(artists);
  }, [artists]);

  useEffect(() => {
    setEditionsSuggestions(editions);
  }, [editions]);

  useEffect(() => {
    setTagApps(apps);
  }, [apps]);

  useEffect(() => {
    setTagChains(chains);
  }, [chains]);

  // useEffect(() => {
  //   setTagNFtContracts(nftContracts);
  // }, [nftContracts]);

  const KeyCodes = {
    comma: 188,
    enter: [10, 13],
  };
  const delimiters = [...KeyCodes.enter, KeyCodes.comma];

  const envTitle = (env) => {
    switch (env) {
      case 'd':
        return 'Development';
      case 't':
        return 'Test';
      case 's':
        return 'Staging';
      case 'p':
        return 'Production';
      default:
        return 'Development';
    }
  };

  const ArtistsComponent = (field) => {
    return (
      <ReactTags
        autofocus={false}
        inputFieldPosition="inline"
        tags={tagsArtists}
        suggestions={artistsSuggestions}
        delimiters={delimiters}
        handleDelete={(i) => {
          setTagsArtists(tagsArtists.filter((tag, index) => index !== i));
          const tempTags = tagsArtists;
          delete tempTags[i];
          const ids = tempTags.map((t) => {
            return t.id;
          });
          field.input.onChange(ids.toString());
        }}
        handleAddition={(tag) => {
          setTagsArtists([...tagsArtists, tag]);
          const ids = tagsArtists.map((t) => {
            return t.id;
          });
          ids.push(tag.id);
          field.input.onChange(ids.toString());
        }}
        classNames={{
          tagInputField: 'form-control',
        }}
        placeholder="Artists"
      />
    );
  };

  const EditionsComponent = (field) => {
    return (
      <ReactTags
        autofocus={false}
        inputFieldPosition="inline"
        tags={tagsEditions}
        suggestions={editionsSuggestions}
        delimiters={delimiters}
        handleDelete={(i) => {
          setTagsEditions(tagsEditions.filter((tag, index) => index !== i));
          const tempTags = tagsEditions;
          delete tempTags[i];
          const ids = tempTags.map((t) => {
            return t.id;
          });
          field.input.onChange(ids.toString());
        }}
        handleAddition={(tag) => {
          setTagsEditions([...tagsEditions, tag]);
          const ids = tagsEditions.map((t) => {
            return t.id;
          });
          ids.push(tag.id);
          field.input.onChange(ids.toString());
        }}
        classNames={{
          tagInputField: 'form-control',
        }}
        placeholder="start typing name of edition..."
        inputProps={{ disabled: tagsEditions.length > 0 || editionsSuggestions.length === 0 }}
      />
    );
  };

  const submit = async (e) => {
    e.preventDefault();
    // eslint-disable-next-line no-restricted-globals
    if (env === 'p') {
      alertConfirm({
        title: 'Confirm Submission',
        content: 'You are submiting to PRODUCTION env, are you sure?',
        okText: 'Ok',
        cancelText: 'Cancel',
        onOk: () => {
          handleSubmit();
        },
        onCancel: () => {
          alertConfirm.alert({
            content: 'Double check environment selection on the header of the page',
            okText: 'Ok',
          });
        },
      });
    } else {
      handleSubmit();
    }
  };

  const renderMetadata = () => {
    if (env === 'p') {
      return (
        <Row className="mb-3">
          <Col md={12} lg={12} xs={12} sm={12}>
            <div className="form-check form-group">
              <Field
                name="is_metadata"
                id="metadata-id"
                className="form-check-input"
                component={'input'}
                type="checkbox"
                onChange={(e) => setMetadataInfo(e.target.checked)}
              />
              <label className="form-check-label" htmlFor={'metadata-id'}>
                {'Create Metadata? (Upload to IPFS first)'}
              </label>
            </div>
          </Col>
          <Col md={6} lg={6} xs={12} sm={12} className={metadataInfo ? null : 'd-none'}>
            <FormField
              id="metadata-image"
              name="metadata_image"
              placeholder="IPFS Image URL"
              inputType="text"
              req={false}
            />
          </Col>
          <Col md={6} lg={6} xs={12} sm={12} className={metadataInfo ? null : 'd-none'}>
            <FormField
              id="metadata-animation"
              name="metadata_animation"
              placeholder="IPFS Animation URL"
              inputType="text"
            />
          </Col>
        </Row>
      );
    }
    return null;
  };

  const renderRoyalties = () => {
    if (env !== 'p') {
      return (
        <>
          <Row className="mb-3">
            <Col md={12} lg={12} xs={12} sm={12}>
              <div className="form-group">
                <label htmlFor={'artist-id'}>{'Artists'}</label>
                <Field
                  id="artist-id"
                  name="artists"
                  component={ArtistsComponent}
                  required={false}
                />
              </div>
            </Col>
          </Row>
          <Row className="mb-3">
            <Col md={4} lg={4} xs={12} sm={12}>
              <FormField
                id="artists-percentage"
                name="artists_percentage"
                placeholder="Artists Percentages (separated by comma, same order added in previously field)"
                inputType="text"
                req={false}
              />
            </Col>
          </Row>
          <Row>
            {/* <Col md={3} lg={3} xs={12} sm={12}>
          <FormField
            id="epik-secondary-sales-royalty"
            name="epik_secondary_sales_royalty"
            placeholder="Epik Secondary Sales Royalty"
            inputType="text"
            req={false}
          />
        </Col> */}
          </Row>
          <Row className="mb-3">
            <Col md={3} lg={3} xs={12} sm={12}>
              <div className="form-group">
                <label htmlFor={'proxy-wallet'}>{'Create Proxy Wallet?'}</label>
                <Field
                  name={'proxy_wallet'}
                  component="select"
                  className="form-control"
                  required={false}
                  onChange={(e) => setCreateProxyWallet(e.target.value === 'yes')}
                >
                  <option value="yes" selected>
                    Yes
                  </option>
                  <option value="no">No</option>
                </Field>
              </div>
            </Col>
            <Col
              md={3}
              lg={3}
              xs={12}
              sm={12}
              className={!createProxyWallet ? 'd-lg-block' : 'd-none'}
            >
              <div className="form-group">
                <label htmlFor={'combine-proxy-wallet'}>
                  {'Share proxy wallet (choose app first)?'}
                </label>
                <Field
                  id="combine-proxy-wallet"
                  name="combine"
                  component="select"
                  className="form-control"
                  required={false}
                  onChange={(e) => {
                    e.target.value === 'yes'
                      ? setIsCombineProxyWallet(true)
                      : setIsCombineProxyWallet(false);
                  }}
                  disabled={editions.length === 0}
                >
                  <option value="no" selected="selected">
                    No
                  </option>
                  <option value="yes">Yes</option>
                </Field>
              </div>
            </Col>
            <Col
              md={3}
              lg={3}
              xs={12}
              sm={12}
              className={isCombineProxyWallet ? 'd-lg-block' : 'd-none'}
            >
              <div className="form-group">
                <label htmlFor={'edition-id'}>{'Edition'}</label>
                <Field
                  id="edition-id"
                  name="edition_id"
                  component={EditionsComponent}
                  required={false}
                />
              </div>
            </Col>
            <Col
              md={3}
              lg={3}
              xs={12}
              sm={12}
              className={!isCombineProxyWallet && !createProxyWallet ? 'd-lg-block' : 'd-none'}
            >
              <FormField
                id="proxy-wallet-address"
                name="proxy_wallet_address"
                placeholder="Enter wallet public address"
                inputType="text"
                req={false}
              />
            </Col>
          </Row>
        </>
      );
    }
    return null;
  };

  const renderNftContracts = () => {
    return (
      <Select
        id="select-nft-contract"
        name="nft_contract"
        placeholder="Select NFT Smart Contract (select network first)"
        options={tagsNftContracts.map((item) => {
          return item.id;
        })}
        optionsLabels={tagsNftContracts.map((item) => {
          return `${item.address} - ${item.tag}`;
        })}
        req={true}
      />
    );
  };

  const renderChains = () => {
    return (
      <Select
        id="chain-id"
        name="chain_id"
        placeholder="Select Network"
        options={tagsChains.map((item) => {
          return item.id;
        })}
        optionsLabels={tagsChains.map((item) => {
          return `${item.id} - ${item.name}`;
        })}
        req={true}
        onChange={(e) => {
          if (env === 'd' || env === 't') {
            if (e.target.value == 1 || e.target.value == 128) {
              alertConfirm.alert({
                content: `Are you sure you want mint on a mainnet from a ${
                  env === 'd' ? 'development' : 'test'
                } environment? Double check please.`,
                okText: 'Ok',
              });
            }
            if (e.target.value == 3 || e.target.value == 4) {
              alertConfirm.alert({
                content:
                  'Ropsten and Rinkeby testnets are no longer working. Please select Goerli.',
                okText: 'Ok',
              });
              return;
            }
          }
          if (env === 's' || env === 'p') {
            if (e.target.value != 1 && e.target.value != 128) {
              alertConfirm.alert({
                content: `Are you sure you want mint on a testnet from a ${
                  env == 's' ? 'staging' : 'production'
                } environment? Double check please.`,
                okText: 'Ok',
              });
            }
          }
          const newNftContracts = [];
          for (const item of nftContracts) {
            if (parseInt(item.chain_id, 10) === parseInt(e.target.value, 10)) {
              newNftContracts.push(item);
            }
          }
          setTagNFtContracts(newNftContracts);
        }}
      />
    );
  };

  return (
    <form onSubmit={submit}>
      <Row>
        <Col md={12} lg={12} xs={12} sm={12}>
          <h2 style={{ textAlign: 'center', color: 'red' }}>{envTitle(env)}</h2>
          {env !== '' ? <hr /> : null}
        </Col>
      </Row>

      <Row className="mb-3">
        <Col md={5} lg={5} xs={12} sm={12}>
          <FormField id="item-name" name="name" placeholder="Name" inputType="text" req={false} />
        </Col>
        <Col md={2} lg={2} xs={12} sm={12}>
          <FormField
            id="item-price"
            name="price"
            placeholder="Price"
            inputType="text"
            req={false}
          />
        </Col>
        <Col md={2} lg={2} xs={12} sm={12}>
          <FormField
            id="item-quantity"
            name="minted"
            placeholder="Quantity"
            inputType="text"
            req={false}
          />
        </Col>
        <Col md={3} lg={3} xs={12} sm={12}>
          <Select
            id="release-status"
            name="release_status"
            placeholder="Release Status"
            options={['hidden', 'released']}
            optionsLabels={['Hidden', 'Released']}
            req={false}
          />
        </Col>
      </Row>
      <Row className="mb-3">
        <Col md={6} lg={6} xs={12} sm={12}>
          <FormField
            id="item_image_1"
            name="item_image_1"
            placeholder="Image URL"
            inputType="url"
            req={false}
          />
        </Col>
        <Col md={6} lg={6} xs={12} sm={12}>
          <FormField
            id="item_image_2"
            name="item_image_2"
            placeholder="Animation URL"
            inputType="url"
          />
        </Col>
      </Row>
      <Row className="mb-3">
        <Col md={12} lg={12} xs={12} sm={12}>
          <p>Additional assets:</p>
        </Col>
        <Col md={4} lg={4} xs={12} sm={12}>
          <FormField
            id="item_image_3"
            name="item_image_3"
            placeholder="Image URL 3"
            inputType="url"
          />
        </Col>
        <Col md={4} lg={4} xs={12} sm={12}>
          <FormField
            id="item_image_4"
            name="item_image_4"
            placeholder="Image URL 4"
            inputType="url"
          />
        </Col>
        <Col md={4} lg={4} xs={12} sm={12}>
          <FormField
            id="item_image_5"
            name="item_image_5"
            placeholder="Image URL 5"
            inputType="url"
          />
        </Col>
      </Row>
      <Row className="mb-3">
        <Col md={3} lg={3} xs={12} sm={12}>
          <Select
            id="select-app"
            name="select_app"
            placeholder="Select App"
            options={tagsApps.map((item) => {
              return item.id;
            })}
            optionsLabels={tagsApps.map((item) => {
              return item.name;
            })}
            req={false}
            // onChange={(e) => handleApp(e.target.value)}
          />
        </Col>

        <Col md={3} lg={3} xs={12} sm={12}>
          <Select
            id="pre-mint"
            name="pre_mint"
            placeholder="Pre Mint?"
            options={[true, false]}
            optionsLabels={['True', 'False']}
            req={true}
          />
        </Col>
        <Col md={3} lg={3} xs={12} sm={12}>
          <Select
            id="can-buy"
            name="can_buy"
            placeholder="Can Buy?"
            options={[true, false]}
            optionsLabels={['True', 'False']}
            req={true}
          />
        </Col>
        <Col md={3} lg={3} xs={12} sm={12}>
          <FormField
            id="batch-id "
            name="batch_id"
            placeholder="Set Batch Id (Leave blank for default)"
            inputType="number"
          />
        </Col>
      </Row>
      <Row className="mb-3">
        <Col md={4} lg={4} xs={12} sm={12}>
          <Select
            id="item-is-redemption"
            name="is_redemption"
            placeholder="Is Redemption?"
            options={[true, false]}
            optionsLabels={['True', 'False']}
            req={false}
          />
        </Col>
        <Col md={4} lg={4} xs={12} sm={12}>
          <FormField
            id="redemption-code-expiration"
            name="redemption_code_expiration"
            placeholder="Redemption Code Expiration"
            inputType="datetime-local"
            req={false}
          />
        </Col>
        <Col md={4} lg={4} xs={12} sm={12}>
          <Select
            id="auto-generate"
            name="auto_generate_redemption_codes"
            placeholder="Generate Redemption Codes?"
            options={[false, true]}
            optionsLabels={['False', 'True']}
            req={false}
          />
        </Col>
      </Row>
      <Row className="mb-3">
        <Col md={4} lg={4} xs={12} sm={12}>
          {renderChains()}
        </Col>
        <Col md={4} lg={4} xs={12} sm={12}>
          {renderNftContracts()}
        </Col>
        <Col md={4} lg={4} xs={12} sm={12}>
          <FormField
            id="ownership"
            name="ownership"
            placeholder="Ownership (address) of items (Leave blank for default Epik)"
            inputType="text"
            req={false}
          />
        </Col>
      </Row>
      <Row className="mb-3">
        <Col md={12} lg={12} xs={12} sm={12}>
          <TextArea
            id="item-description"
            name="description"
            placeholder="Description"
            req={false}
          />
        </Col>
      </Row>
      {renderMetadata()}
      {renderRoyalties()}
      <Row className="mb-3">
        <Col md={6} lg={6} xs={12} sm={12}>
          <FormField
            id="gas-limit"
            name="gas_limit"
            placeholder="Gas Limit in WEI (Leave blank for default)"
            inputType="number"
            req={false}
          />
        </Col>
        <Col md={6} lg={6} xs={12} sm={12}>
          <FormField
            id="gas-price"
            name="gas_price"
            placeholder="Gas Price in GWEI (Leave blank for default)"
            inputType="number"
            req={false}
          />
        </Col>
      </Row>
      <Row className="mb-3">
        <Col md={12} lg={12} xs={12} sm={12}>
          <p>
            Useful Links:
            <br />
            <a href="https://eth-converter.com" rel="noopener noreferrer" target="_blank">
              Ethereum Unity Converter
            </a>
            <br />
            <a href="https://etherscan.io/gastracker" rel="noopener noreferrer" target="_blank">
              Gas Tracker
            </a>
          </p>
        </Col>
        <Col md={12} lg={12} xs={12} sm={12}>
          <hr />
        </Col>
        <Col md={12} lg={12} xs={12} sm={12}>
          <div className="form-group">
            <button type="submit" className="btn btn-primary">
              Send
            </button>
          </div>
        </Col>
      </Row>
    </form>
  );
};

export default reduxForm({ form: 'itemForm' })(ItemForm);
