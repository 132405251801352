import {
  LOGIN,
  LOGOUT,
  GET_APPS,
  LOGIN_ERROR,
  SHOW,
  GET_CHAINS,
  // GET_CHAINS_ERROR,
  GET_NFT_CONTRACTS,
  // GET_NFT_CONTRACTS_ERROR,
} from '../actionTypes';

const initialState = {
  isLoggedIn: false,
  acl: localStorage.getItem('acl') ? localStorage.getItem('acl') : null,
  show: '',
  alert: '',
  message: '',
  apps: [],
  chains: [],
  nftContracts: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case LOGOUT:
      return { ...state, isLoggedIn: action.payload.isLoggedIn };
    case LOGIN:
      return { ...state, isLoggedIn: action.payload.isLoggedIn, acl: action.payload.acl };
    case LOGIN_ERROR:
      return {
        ...state,
        show: action.payload.show,
        message: action.payload.message,
        alert: action.payload.alert,
      };
    case GET_APPS:
      return { ...state, apps: action.payload.apps };
    case GET_CHAINS:
      return { ...state, chains: action.payload.chains };
    case GET_NFT_CONTRACTS:
      return {
        ...state,
        nftContracts: action.payload.nftContracts,
      };
    case SHOW:
      return { ...state, show: '' };
    default:
      return state;
  }
}
