import decode from 'jwt-decode';

export default class Auth {
  loggedIn = () => {
    // Checks if there is a saved token and it's still valid
    const token = this.getToken(); // Getting token from localstorage
    return !!token && !this.isTokenExpired(token); // handwaiving here
  };

  isTokenExpired = (token) => {
    try {
      const decoded = decode(token);
      if (decoded.exp < Date.now() / 1000) {
        // Checking if token is expired.
        return true;
      } else return false;
    } catch (err) {
      console.log('expired check failed! Line 42: AuthService.js');
      return false;
    }
  };

  setToken = (token, acl) => {
    // Saves user token to localStorage
    localStorage.setItem('auth', token);
    localStorage.setItem('acl', acl);
  };

  getToken = () => {
    // Retrieves the user token from localStorage
    let token = localStorage.getItem('auth');
    return token;
  };

  getAcl = () => {
    // Retrieves the user acl from localStorage
    return localStorage.getItem('acl');
  };

  logout = () => {
    // Clear user token and profile data from localStorage
    localStorage.removeItem('auth');
    localStorage.removeItem('acl');
  };

  getConfirm = () => {
    // Using jwt-decode npm package to decode the token
    let answer = decode(this.getToken());
    console.log('Recieved answer!');
    return answer;
  };
}
