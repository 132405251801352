import React, { Component } from 'react';
import { Row, Col, Table } from 'react-bootstrap';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  DatatableWrapper,
  Filter,
  Pagination,
  TableBody,
  TableHeader,
  PaginationOptions,
} from 'react-bs-datatable';
import { getUserItems } from '../../redux/actions';
import Page from '../../layout/page';
import Spin from '../../components/Spin';

const header = [{ title: 'Token Id', prop: 'token_id', sortable: true, filterable: true }];

class ViewUser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: {
        user_id: '',
        app_id: '',
        pub_key: '',
        country: '',
        createdAt: '',
      },
    };
  }

  componentDidMount() {
    for (let user of this.props.usr) {
      if (String(user.user_id) === String(this.props.match.params.id)) {
        this.setState({ user });
        this.props.getUserItems(user.pub_key);
        break;
      }
    }
  }

  render() {
    if (this.props.loading) {
      return <Spin />;
    }
    return (
      <Page title={'Users'}>
        <Col md={12} lg={12} xs={12}>
          <div className="card">
            <div className="card-header">User</div>
            <div className="card-body">
              <Row>
                <Col md={6} lg={6} xs={12} sm={12}>
                  <div className="form-group">
                    <label>User Id</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="User Id"
                      value={this.state.user.user_id}
                      disabled
                    />
                  </div>
                </Col>
                <Col md={3} lg={3} xs={12} sm={12}>
                  <div className="form-group">
                    <label>Wallet address</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Wallet address"
                      value={this.state.user.pub_key}
                      disabled
                    />
                  </div>
                </Col>
                <Col md={3} lg={3} xs={12} sm={12}>
                  <div className="form-group">
                    <label>Country</label>
                    <input
                      type="url"
                      className="form-control"
                      placeholder="Country"
                      value={this.state.user.country ? this.state.user.country : ''}
                      disabled
                    />
                  </div>
                </Col>
                <Col md={12} lg={12} xs={12} sm={12}>
                  <div className="form-group">
                    <label>Created At</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Created At"
                      value={this.state.user.createdAt}
                      disabled
                    />
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </Col>
        <Col md={12} lg={12} xs={12}>
          <hr />
        </Col>
        <Col md={12} lg={12} xs={12}>
          <div className="card">
            <div className="card-header">User Items</div>
            <div className="card-body">
              <Row>
                <Col md={12} lg={12} xs={12}>
                  <Row>
                    <Col md={12} lg={12} xs={12}>
                      <DatatableWrapper
                        headers={header}
                        body={this.props.items}
                        paginationOptionsProps={{
                          initialState: {
                            rowsPerPage: 15,
                            options: [15, 20, 25, 30],
                          },
                        }}
                      >
                        <Row className="mb-4 p-2">
                          <Col
                            xs={12}
                            lg={4}
                            className="d-flex flex-col justify-content-end align-items-end"
                          >
                            <Filter />
                          </Col>
                          <Col
                            xs={12}
                            sm={6}
                            lg={4}
                            className="d-flex flex-col justify-content-lg-center align-items-center justify-content-sm-start mb-2 mb-sm-0"
                          >
                            <PaginationOptions />
                          </Col>
                          <Col
                            xs={12}
                            sm={6}
                            lg={4}
                            className="d-flex flex-col justify-content-end align-items-end"
                          >
                            <Pagination />
                          </Col>
                        </Row>
                        <Table>
                          <TableHeader />
                          <TableBody />
                        </Table>
                      </DatatableWrapper>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>
          </div>
        </Col>
      </Page>
    );
  }
}

const mapStateToProps = (state) => ({
  loading: state.users.loading,
  items: state.users.items,
  usr: state.users.usr,
});
const mapDispatchToProps = (dispatch) => bindActionCreators({ getUserItems }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ViewUser);
