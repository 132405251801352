import {
  LOADING,
  SHOW,
  ALL_ITEMS,
  ALL_ITEMS_ERROR,
  ADD_ITEM,
  ADD_ITEM_ERROR,
  GET_ARTISTS,
  GET_ARTISTS_ERROR,
  ALL_IPFS_FILES,
  ALL_IPFS_FILES_ERROR,
  GET_EDITIONS,
  GET_EDITIONS_ERROR,
} from '../actionTypes';

const initialState = {
  show: false,
  alert: '',
  message: '',
  items: [],
  files: [],
  artists: [],
  loading: false,
  editions: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case ALL_ITEMS:
      return { ...state, items: action.payload.items };
    case ALL_ITEMS_ERROR:
      return {
        ...state,
        items: action.payload.items,
        show: action.payload.show,
        message: action.payload.message,
        alert: action.payload.alert,
      };
    case ALL_IPFS_FILES:
      return { ...state, files: action.payload.files };
    case ALL_IPFS_FILES_ERROR:
      return {
        ...state,
        show: action.payload.show,
        message: action.payload.message,
        alert: action.payload.alert,
      };
    case ADD_ITEM:
      return {
        ...state,
        show: action.payload.show,
        message: action.payload.message,
        alert: action.payload.alert,
      };
    case ADD_ITEM_ERROR:
      return {
        ...state,
        items: action.payload.items,
        show: action.payload.show,
        message: action.payload.message,
        alert: action.payload.alert,
      };
    case GET_ARTISTS:
      return { ...state, artists: action.payload.artists.artists };
    case GET_ARTISTS_ERROR:
      return {
        ...state,
        show: action.payload.show,
        message: action.payload.message,
        alert: action.payload.alert,
      };
    case GET_EDITIONS:
      return {
        ...state,
        editions: action.payload.editions.editions,
      };
    case GET_EDITIONS_ERROR:
      return {
        ...state,
        show: action.payload.show,
        message: action.payload.message,
        alert: action.payload.alert,
      };
    case LOADING:
      return { ...state, loading: action.payload };
    case SHOW:
      return { ...state, show: action.payload };
    default:
      return state;
  }
}
