import React, { Component } from 'react';
import { Col, Alert, FormGroup, Row, Input, Label } from 'reactstrap';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import CodeEditor from '@uiw/react-textarea-code-editor';
import axios from 'axios';

import { dismissAlert, handleLoading } from '../../redux/actions';
import Page from '../../layout/page';
import Spin from '../../components/Spin';

class UploadData extends Component {
  constructor(props) {
    super(props);
    this.onDismiss = this.onDismiss.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.fileOnChange = this.fileOnChange.bind(this);
    this.textOnChange = this.textOnChange.bind(this);
    this.state = { textData: '', fileUrl: '', nameData: '' };
  }

  // changeHandler = event => {
  //   const field = event.target.name;
  //   const add = this.state.add;
  //   add[field] = event.target.value;
  //   return this.setState({add});
  // }
  async handleSubmit() {
    try {
      this.props.handleLoading(true);
      const url = `https://api.pinata.cloud/pinning/pinJSONToIPFS`;

      const dataSet = {
        pinataMetadata: {
          name: this.state.nameData,
        },
        pinataContent: this.state.textData,
      };

      const response = await axios.post(url, dataSet, {
        maxBodyLength: 'Infinity',
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_PINATA_BEARER}`,
        },
      });
      this.setState({
        fileUrl: `https://ipfs.epikprime.com/ipfs/${response.data.IpfsHash}`,
      });
      this.props.handleLoading(false);
    } catch (error) {
      console.log(error);
    }
  }

  fileOnChange(e) {
    const data = e.target.value;
    this.setState({
      textData: data,
    });
  }

  textOnChange(e) {
    const data = e.target.value;
    this.setState({
      nameData: data,
    });
  }

  componentDidMount() {
    return this.props.handleLoading(false);
  }

  onDismiss() {
    return this.props.dismissAlert();
  }

  render() {
    //console.log(this.props)
    if (this.props.loading) {
      return <Spin />;
    }
    return (
      <Page title={'Upload text data (JSON object) to IPFS'}>
        <Col md={12} lg={12} xs={12}>
          <div className="card">
            <div className="card-header">Upload Data</div>
            <div className="card-body">
              <form onSubmit={this.handleSubmit}>
                <Row>
                  <Col md={6} lg={6} xs={12} sm={12}>
                    <FormGroup>
                      <Label for="fileName">Name:</Label>
                      <Input type="text" name="name" id="fileName" onChange={this.textOnChange} />
                    </FormGroup>
                  </Col>
                  <Col md={12} lg={12} xs={12} sm={12}>
                    <FormGroup>
                      <Label for="file">Type or paste a valid JSON object:</Label>
                      <CodeEditor
                        value={this.state.textData}
                        language="js"
                        placeholder="Please enter a JSON object."
                        onChange={this.fileOnChange}
                        padding={15}
                        minHeight={'48'}
                        style={{
                          fontSize: 12,
                          backgroundColor: '#f5f5f5',
                          fontFamily:
                            'ui-monospace,SFMono-Regular,SF Mono,Consolas,Liberation Mono,Menlo,monospace',
                        }}
                      />
                    </FormGroup>
                  </Col>

                  <Col md={12} lg={12} xs={12} sm={12}>
                    <div className="form-group">
                      <button type="submit" className="btn btn-primary">
                        Upload
                      </button>
                    </div>
                    <hr />
                  </Col>
                  <Col md={12} lg={12} xs={12} sm={12}>
                    <FormGroup>
                      <Label for="exampleFile">File URL</Label>
                      <Input type="text" value={this.state.fileUrl} disabled />
                    </FormGroup>
                  </Col>
                </Row>
              </form>

              <Alert
                color={this.props.alert}
                isOpen={this.props.show === 'addItem'}
                toggle={this.onDismiss}
              >
                {this.props.message}
              </Alert>
            </div>
          </div>
        </Col>
      </Page>
    );
  }
}
const mapStateToProps = (state) => ({
  loading: state.editions.loading,
  show: state.editions.show,
  message: state.editions.message,
  alert: state.editions.alert,
});
const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ dismissAlert, handleLoading }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(UploadData);
