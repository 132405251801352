import React, { Component } from 'react';
import { Alert } from 'reactstrap';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { login, dismissAlert } from '../../redux/actions';
import Spin from '../../components/Spin';
import 'bootstrap/dist/css/bootstrap.css';
import '../../assets/css/login.css';

class Login extends Component {
  constructor(props) {
    super(props);
    if (this.props.isLoggedIn) {
      this.props.history.push('/items/all-items');
    }
    this.onDismiss = this.onDismiss.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.changeHandler = this.changeHandler.bind(this);
    this.state = { credentials: { email: '', password: '' } };
  }

  componentDidMount() {
    if (this.props.isLoggedIn) {
      this.props.history.push('/items/all-items');
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.isLoggedIn) {
      this.props.history.push('/items/all-items');
    }
  }

  changeHandler = (event) => {
    const field = event.target.name;
    const credentials = this.state.credentials;
    credentials[field] = event.target.value;
    return this.setState({ credentials: credentials });
  };

  handleSubmit(event) {
    event.preventDefault();
    this.props.login(this.state.credentials);
  }

  onDismiss() {
    this.props.dismissAlert();
  }

  isOpen() {
    window.setTimeout(() => {
      this.onDismiss();
      return false;
    }, 5000);
  }

  render() {
    if (this.props.loading) {
      return <Spin />;
    }
    return (
      <div className="login-form">
        <form onSubmit={this.handleSubmit}>
          <h1 className="text-center">
            {' '}
            <img
              className="img-fluid"
              src={require('../../assets/img/logo.png')}
              alt="Epik"
              width="25%"
            />
          </h1>
          <h2 className="text-center">Login</h2>
          <div className="form-group">
            <div className="mb-3">
              <input
                type="email"
                className="form-control"
                name="email"
                placeholder="email"
                value={this.state.credentials.email}
                onChange={this.changeHandler}
                required="required"
              />
            </div>
            <div className="mb-3">
              <input
                type="password"
                className="form-control"
                name="password"
                placeholder="Password"
                value={this.state.credentials.password}
                onChange={this.changeHandler}
                required="required"
              />
            </div>
          </div>
          <div className="form-group">
            <button type="submit" className="btn btn-primary btn-block">
              Log in
            </button>
          </div>
          <Alert
            color={this.props.alert}
            isOpen={this.props.show === 'login' && this.isOpen()}
            toggle={this.onDismiss}
          >
            {this.props.message}
          </Alert>
        </form>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  loading: state.admin.loading,
  isLoggedIn: state.admin.isLoggedIn,
  show: state.admin.show,
  alert: state.admin.alert,
  message: state.admin.message,
});
const mapDispatchToProps = (dispatch) => bindActionCreators({ login, dismissAlert }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Login);
